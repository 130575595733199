<template>
  <div>
      <div
        class="text-caption mt-n1 mb-1"
        v-if="search.queryDetails"
      >
        <v-icon
          :color="search.sendUpdatesPerEmail.daily
            || search.sendUpdatesPerEmail.realTime
            || search.sendUpdatesPerEmail.weekly ? 'teal lighten-1' : 'grey lighten-1'"
        >
          mdi-email
        </v-icon>
      </div>
      <div
        class="custom-search-title text-body-2 font-weight-medium line-clamp-two"
        v-if="search.title"
        style="max-height: 48px;"
      >
        {{ search.title }}
      </div>
      <div
        class="text-body-2 mt-2 text-truncate"
        v-if="search.queryDetails.query"
      >
        {{ search.queryDetails.query }}
      </div>
      <div
        class="text-caption mt-2 line-clamp-three"
        style="font-size: 80% !important;"
      >
        {{ selectedGroupPaths }}
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GenericSavedSearchItemDetail',

  props: {
    dashboard: {
      type: String,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      userFilters: 'userFilters',
      userPreferences: 'userPreferences',
    }),

    locale() {
      // TODO: POLMONNL-6203 - Refactor away this hack
      return this.userPreferences.locale;
    },

    selectedGroupPaths() {
      const genericGrouping = this.userFilters[`${this.dashboard}Grouping`];
      if (genericGrouping) {
        const { queryDetails: { groupPaths = {} } } = this.search;
        const result = [];
        const topLevelGroups = Object.keys(genericGrouping.groups);
        if (groupPaths) {
          if (groupPaths.length === 0) {
            topLevelGroups.forEach((groupCode) => {
              const group = genericGrouping.groups[groupCode];
              result.push(`${this.localizedGroupname(group, this.locale)} (${Object.keys(group.subGroups).length})`);
            });
          } else {
            const counters = {};
            groupPaths.forEach((item) => {
              // Top-level:
              if (topLevelGroups.includes(item)) {
                const group = genericGrouping.groups[item];
                result.push(`${this.localizedGroupname(group, this.locale)} (${Object.keys(group.subGroups).length})`);
              } else {
                // Subgroups:
                const parentGroup = item.split('/')[0];
                counters[parentGroup] = counters[parentGroup] ? counters[parentGroup] + 1 : 1;
              }
            });
            Object.keys(counters).forEach((counter) => {
              const group = genericGrouping.groups[counter];
              result.push(`${this.localizedGroupname(group, this.locale)} (${counters[counter]})`);
            });
          }
        }
        return result.toString().replaceAll(',', ', ');
      }
      return null;
    },
  },

  methods: {
    initSelectedGroupPaths() {
      return this.selectedGroupPaths;
    },

    localizedGroupname(group, locale) {
      return `${group.localization[locale] || group.localization.nl}`;
    },
  },

  mounted() {
    this.initSelectedGroupPaths();
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1904px) {
  .custom-search-title {
    font-size: 13.6px !important;
  }
}
</style>
