<template>
  <div>
      <div
        class="text-caption mt-n1 mb-1"
        v-if="search.queryDetails && search.queryDetails.query"
      >
        <v-icon color="grey lighten-1">
          mdi-email
        </v-icon>
      </div>
      <div
        class="text-body-2 mt-2 line-clamp-three"
        v-if="search.queryDetails && search.queryDetails.query"
      >
        {{ search.queryDetails.query }}
      </div>
      <div
        v-if="search.queryDetails"
        class="text-caption mt-2 line-clamp-three"
        style="font-size: 80% !important;"
      >
        {{ selectedFiltersShorthand }}
      </div>
  </div>
</template>

<script setup>
import useEuSavedSearches from '@/composables/euSavedSearchesComposable';

const props = defineProps({
  search: {
    type: Object,
    required: true,
  },
});

const {
  selectedFiltersShorthand,
} = useEuSavedSearches(props);
</script>

<script>
// This is here because we need a name in our namespace :)
// See https://stackoverflow.com/questions/67669820/how-to-define-component-name-in-vue3-setup-tag
export default {
  name: 'EuSavedSearchesItemDetailRecent',
};
</script>
