<template>
  <div class="fill-height">

    <div
      class="text-body-1 font-weight-medium ml-n1 line-clamp-two"
      style="max-height: 48px;"
      v-if="search.title"
    >
        <v-icon
          :color="search.includeInWebcal
          || search.sendUpdatesPerEmail.activitiesFrequent.enabled
          || search.sendUpdatesPerEmail.activitiesOverview.enabled
            ? 'teal lighten-2' : 'grey lighten-1'"
        >
          mdi-calendar-blank
        </v-icon>

      {{ search.title }}
    </div>

    <div class="text-caption mt-2">
      {{ search.queryDetails.filters.length }} filters
    </div>

    <div
      class="text-body-2 mt-2 text-truncate"
      v-if="search.queryDetails.query"
    >
      {{ search.queryDetails.query }}
    </div>

    <div style="height:100%">
      <div class="bottom">
        <div
          class="text-caption"
          :class="this.$vuetify.theme.dark ? 'text--disabled' : 'grey--text text--lighten-1'"
        >
          {{ $t('agenda.synchronisation') }} <span class="font-weight-bold">{{`${search.includeInWebcal ? `${$t('agenda.on')}` : `${$t('agenda.off')}`}`}}</span>
        </div>
        <!-- Disable this option because it hasn't been implemented yet -->
        <!-- <div
          class="text-caption"
          :class="this.$vuetify.theme.dark ? 'text--disabled' : 'grey--text text--lighten-1'"
        >
          Dagelijkse e-mail <span class="font-weight-bold">{{`${search.sendUpdatesPerEmail.dailyChanges ? 'aan' : 'uit'}`}}</span>
        </div> -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AgendaSavedSearchesItemDetailSaved',

  props: {
    search: {
      type: Object,
      required: true,
    },
  },
};
</script>


<style lang="scss" scoped>
  .bottom {
    position: absolute;
    bottom: 16px;
  }
</style>
