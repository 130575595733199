<template>
  <div>
      <div
        class="text-caption mt-n1 mb-1"
        v-if="search.queryDetails"
      >
        <v-icon
          :color="search.sendUpdatesPerEmail.daily
            || search.sendUpdatesPerEmail.realTime
            || search.sendUpdatesPerEmail.weekly ? 'teal lighten-1' : 'grey lighten-1'"
        >
          mdi-email
        </v-icon>
      </div>
      <div
        class="custom-search-title text-body-2 font-weight-medium line-clamp-two"
        v-if="search.title"
        style="max-height: 48px;"
      >
        {{ search.title }}
      </div>
      <div
        class="text-body-2 mt-2 text-truncate"
        v-if="search.queryDetails.query"
      >
        {{ search.queryDetails.query }}
      </div>
      <div
        class="text-caption mt-2 line-clamp-three"
        style="font-size: 80% !important;"
      >
        {{ selectedFiltersShorthand }}
      </div>
  </div>
</template>

<script setup>
import useEuSavedSearches from '@/composables/euSavedSearchesComposable';

const props = defineProps({
  search: {
    type: Object,
    required: true,
  },
});

const {
  selectedFiltersShorthand,
} = useEuSavedSearches(props);
</script>

<script>
// This is here because we need a name in our namespace :)
// See https://stackoverflow.com/questions/67669820/how-to-define-component-name-in-vue3-setup-tag
export default {
  name: 'eu-saved-searches-item-detail-saved',
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1904px) {
  .custom-search-title {
    font-size: 13.6px !important;
  }
}
</style>
