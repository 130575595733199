<template>
  <v-dialog
    v-model="dialog"
    max-width="512"
    @click:outside="closeAction()"
  >
    <v-card>
      <v-card-title>
        <v-container class="ma-0 pa-0">
          <v-row>
            <v-col class="d-flex mt-n2">
              <div
                class="dialog-title d-inline-block align-self-start text-subtitle-1
              "
                style="width: 80%"
              >
                {{ $t('savedSearches.shareQuery')}}
              </div>
              <div
                class="d-inline-block text-right align-self-start"
                style="width: 20%"
              >
                <v-icon
                  class="mt-n2 mr-n2"
                  @click="closeAction()"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text class="dialog-text">
        {{ $t('savedSearches.copyOrShareURL')}}
        <br><br>
        <v-text-field
          style="position: absolute; left: -9999px;"
          v-model="computedSavedSearchShareUrl"
          ref="textToCopy"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          class="white--text"
          color="teal lighten-1"
          @click="copySavedSearchSourceUrl"
        >
          {{$t('savedSearches.copyURL')}}
          <v-icon
            right
          >
            mdi-content-copy
          </v-icon>
        </v-btn>

        <v-btn
          class="white--text"
          color="teal lighten-1"
          @click="shareByMail"
        >
          {{ $t('savedSearches.shareByEmail')}}
          <v-icon
            right
          >
            mdi-email
          </v-icon>
        </v-btn>

        <v-btn
          v-if="data.dashboard === 'nl'"
          class="white--text"
          color="teal lighten-1"
          @click="shareWithAgenda"
        >
          {{ $t('savedSearches.openInAgenda')}}
          <v-icon
            right
          >
            mdi-calendar
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { hideDialog } from '@/helpers/dialogHelper';

export default {

  data() {
    return {
      dialog: true,
    };
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          search: null,
          dashboard: null,
        };
      },
    },
  },

  computed: {
    renderQueryParams() {
      const queryParams = {
        query: this.data.search.queryDetails.query,
      };
      if (this.data.dashboard !== 'agenda') {
        queryParams.groupPaths = this.data.search.queryDetails.groupPaths.join(',');
        if (this.data.dashboard === 'nl') {
          queryParams.context = this.data.search.queryDetails.context.join(',');
        } else if (this.data.dashboard === 'eu') {
          queryParams.committees = this.data.search.queryDetails.euEpCommittees.join(',') || [];
        }
      }
      return queryParams;
    },

    computedSavedSearchShareUrl() {
      const queryParams = new URLSearchParams(this.renderQueryParams).toString();
      const strippedUrl = window.location.href.split('?')[0];
      return `${strippedUrl}?${queryParams}`;
    },

    renderDocumentQueryParam() {
      if (this.data.dashboard === 'nl') {
        return {
          query: this.data.search.queryDetails.query,
        };
      }
      return null;
    },
  },

  methods: {
    hideDialog,

    shareWithAgenda() {
      const queryParam = new URLSearchParams(this.renderDocumentQueryParam).toString();
      const agedaUrl = `/agenda?${queryParam}`;

      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: agedaUrl });
      } else {
        window.open(agedaUrl, '_blank');
      }

      this.closeAction();
    },

    copySavedSearchSourceUrl() {
      const textToCopy = this.$refs.textToCopy.$el.querySelector('input');
      textToCopy.select();
      document.execCommand('copy');

      setTimeout(() => {
        this.closeAction();
        this.$store.dispatch('setMessage', {
          message: this.$t('savedSearches.copiedSavedSearch'),
          type: 'info',
        });
      }, 300);
    },

    shareByMail() {
      let ebody = `${this.data.search.title ? `${this.data.search.title}\r\n\r\n` : ''}${this.computedSavedSearchShareUrl ? `${this.computedSavedSearchShareUrl}\r\n\r\n${this.$t('generic.broughtToYouBy')} https://www.polpo.nl` : ''}`;
      ebody = encodeURIComponent(ebody);
      setTimeout(() => {
        window.open(`mailto:username@example.com?subject=${this.data.search.title}&body=${ebody}`);
        this.closeAction();
      }, 300);
    },

    closeAction() {
      this.hideDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
  .dialog-title {
    font-size: 16px !important;
  }
  .dialog-text {
    font-size: 13.4px !important;
  }
</style>
