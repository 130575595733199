<template>
  <div>
    <div
      class="text-caption mt-n1 mb-1"
      v-if="search.queryDetails
        && search.queryDetails.minimalMatchPercentage"
    >
      <v-icon color="grey lighten-1">
        mdi-email
      </v-icon>
      <span v-if="showPercentage">
        {{ search.queryDetails.minimalMatchPercentage }}%
      </span>
    </div>
    <!-- <div
      class="text-body-1 font-weight-medium"
      v-if="search.title"
    >{{ search.title }}</div> -->
    <div
      class="text-body-2 mt-2 line-clamp-three"
      v-if="search.queryDetails && search.queryDetails.query"
    >
      {{ search.queryDetails.query }}
    </div>
    <div
      class="text-caption mt-2 line-clamp-three"
      v-if="search.queryDetails && search.queryDetails.context.length > 0"
    >
      {{ searchContext() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mapNewMinistryCodes from '@/helpers/contextFilterTranslator';

export default {
  name: 'NlDocumentSavedSearchesItemDetailRecent',

  props: {
    search: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      filters: 'userFilters',
    }),

    showPercentage() {
      if (this.search.queryDetails.context.length
        === this.filters.context.length) return false;
      return true;
    },
  },

  methods: {
    searchContext() {
      return this.search.queryDetails.context.map((context) => mapNewMinistryCodes(context)).join(', ');
    },
  },
};
</script>
